<template>
	<div >
		<v-card >
			<v-card style="margin-top: 15px; margin-bottom: 10px;">
				<s-toolbar
					label="Cambio de Posicion"	
					save
                    dark
					color="#2955c8"
                    @save="save()"
                    close
                    @close="$emit('EndProcess', null)"
				>

				</s-toolbar>
				 
				<v-row style="margin: auto">
					<!-- <v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							@onData="onData($event)"
							:readonly="false"
						></s-scanner-qr>
					</v-col> -->
                    <v-col cols="12">
						<chamber-rows-list v-if="chaID != 0" :ChaID="chaID" @select="selectrow($event)"></chamber-rows-list>
                    </v-col>
					<v-col cols="12">
						<chamber-position-list v-if="chaID != 0" :ChaID="chaID" @select="selectPos($event)"></chamber-position-list>
                    </v-col>
					<v-col cols="12">
						<chamber-column-list v-if="chaID != 0" :ChaID="chaID" @select="selectcol($event)"></chamber-column-list>
                    </v-col>
				</v-row>
			</v-card>
		</v-card>   
         
	</div>
</template>

<script>
    import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";
	import _sPrfCoolingChamber from "@/services/FreshProduction/PrfCoolingChamberService";

    import SupplyChamberSelect from "./SupplyChamberSelect.vue";
	import ChamberRowsList from "./ChamberRowsList.vue";
	import ChamberColumnList from "./ChamberColumnList.vue";
	import ChamberPositionList from "./ChamberPositionList.vue";
	// import _sLotProdCalibratedSupplyService from "../../../services/FreshProduction/PrfLotProdCalibratedSupplyService";
	// import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";

	export default {
        props:{
            chaID : {
                required: false,
				default: 0,
            },
            PltID : {
                required: false,
				default: 0,
            },
        },
		components: {
            SupplyChamberSelect,
			ChamberRowsList,
			ChamberColumnList,
			ChamberPositionList
        },
		data: () => ({
			Optional: 0,
            openDialog: true,
            selectedChamber: {},
			configScann: {},
			
			
			chcID : 0,
			chrID : 0,
			chpID : 0,
		}),
		watch: {
			chaID(){
				console.log("ccambio chaid", this.chaID)
			}
		},
		methods: {
			selectChamber(chamber){
                this.openDialog = false;

                this.selectedChamber = chamber;
				this.chaID = chamber.ChaID;
            },
			onValue(val) {
				 console.log("onvalue", val);
				 //aqui
				// this.save(val);
			},

			onData(val) {
				console.log("onData", val);
			},
			selectrow(item){
				this.chrID = item.ChrID;
			},
			selectcol(item){
				this.chcID = item.ChcID;
			},
			selectPos(item){
				this.chpID = item.ChpID;
			},
			save(){
				this.$fun.alert("¿Seguro de pasar a Cámara?", "question")
					.then((val) => {
						if (val.value) {
							let coc = {
								PltID: this.PltID,
								ChaID: this.chaID,
								ChcID: this.chcID,
								ChrID: this.chrID,
								ChpID: this.chpID,
								// TypeChamberTunnel: this.item.TypeChamberTunnel,
								CocStatus: 1,
								SecStatus: 1,
								UsrCreateID: this.$fun.getUserID(),
							};

							_sPrfCoolingChamber
								.save(coc, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) { 
											this.$fun.alert( "Guardado Correctamente", "success" );
                                            this.$emit("EndProcess", coc)
										}
									});
						}
					}) 
			}
		},
	};
</script>
