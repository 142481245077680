<template>
	<div >
		<v-card style="margin-top: 15px; margin-bottom: 10px;">
			<s-toolbar
					label="Cambio de Hora de Registro"	
					save
                    dark
					color="#2955c8"
                    @save="save()"
                    close
                    @close="$emit('EndProcess', null)"
			>

			</s-toolbar>
            <v-row style="margin: auto">
				<v-col>
                    <s-text
                        label="Fecha Registrado"
                        v-model="CocDateBeginR"
                        readonly
                    >
                        
                    </s-text>
					<s-date-time
						label="Nueva Fecha y Hora"
						v-model="CocDateBegin"
					></s-date-time>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
	import _sPrfCoolingChamber from "@/services/FreshProduction/PrfCoolingChamberService";
	import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
    import SupplyChamberSelect from "./SupplyChamberSelect.vue";

	export default {
        props:{
            CocID: {
                required: false,
				default: 0,
            },
            CocDateBeginR: {}           
        },
		components: {
            SupplyChamberSelect,
        },
		data: () => ({
			Optional: 0,
            openDialog: true,
            selectedChamber: {},
			configScann: {},
			CocDateBegin:"",
			report: {},
		}),
		watch: {
			chaID(){
				console.log("ccambio chaid", this.chaID)
			}
		},
		methods: {
			selectChamber(chamber){
                this.openDialog = false;

                this.selectedChamber = chamber;
				this.ChaID = chamber.ChaID;
            },
			onValue(val) {
				 console.log("onvalue", val);
				 //aqui
				// this.save(val);
			},

			onData(val) {
				console.log("onData", val);
			},
			save(){
				//this.getReportCoolingChamber();
                if(this.CocDateBegin === "" ){
                console.log("fecha11",this.CocDateBegin);
                this.$fun.alert("Por favor seleccion una nueva fecha y hora ", "warning");
                return;
                }
                
				this.$fun.alert("¿Seguro de editar  la hora de registro?", "question")
					.then((val) => {
						if (val.value) {
							let coc = {
								//PltID: this.PltID,
								//ChaID: this.ChaID,
								CocID: this.CocID,						
								SecStatus: 1,
                                CocDateBegin: this.CocDateBegin,
								UsrCreateID: this.$fun.getUserID(),
                               // UsrUpdateID: this.$fun.getUserID(),
							};
                            console.log("COC", coc);
                            console.log(this.CocDateBegin);
							_sPrfCoolingChamber
								.save(coc, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) { 
											this.$fun.alert( "Editado Correctamente", "success" );
                                            this.$emit("EndProcess", coc)
										}
									});
						}
					}) 
			},

			getReportCoolingChamber()
            {
                let parametervalue = [];
                let parametergen = "";
                
                let report3 = {
                    QryNameReport : " ",
                    QryParamsArray : parametergen,
                    QryParamsArrayValue : parametervalue.join(","),
                    QryNameProcedure : "REPORT_PALLET_CHAMBER"
                }
				
                //console.log('parametervalue ', this.report2);
				console.log("User",this.$fun.getUserID())
				return new Promise(
                    resolve => {
                        _sQryConfigurationService
                        .querygeneral(report3, this.$fun.getUserID())
                        .then(
                            (res) => {
                                if (res.status == 200) {
                                   console.log("Respuesta2", res);
								   this.downloadReport("REPORTE DE PALLET EN CAMARA","REPORT_PALLET_CHAMBER",parametervalue,parametergen);
                                }
                            }
                        )
                    }
                );
            },
			downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = ""	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = ""; //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;
					
				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;
								
								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			},
		},
	};
</script>
