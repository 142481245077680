import Service from '../Service';

const resource = "chamberColumn/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(cha, requestID) {
        return Service.post(resource + 'save', cha, {
            params: { requestID }
        });
    },

    list(filter, requestID) {
        return Service.post(resource + 'list', filter, {
            params: { requestID }
        });
    },
 
}