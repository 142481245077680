<template>
    <div> 
        <!-- <v-radio-group
            
            row
            >
            <v-radio 
                
                :label="item.ChrDescription"
                :value="item.ChrID"
            ></v-radio>
         </v-radio-group>  v-model="text" -->
         <p>Columnas</p>
        <v-btn-toggle 
          color="warning" 
          group
        >
          <v-btn 
            @click="select(item)"
            v-for="item in itemsRows" :value="item.ChcID">
            {{item.ChcDescription}}
          </v-btn> 
        </v-btn-toggle>
     
    </div>
</template>
<script>

import _sPrfChamberColumnService from "@/services/FreshProduction/PrfChamberColumnService";

    export default {
        props: {
            ChaID : {
				required: false,
				default: 0,
            }
        },
  		components: {  },
		data () {
			return { 
                itemsRows: [],
            }
        },
        watch:{
            ChaID(){
                console.log(this.ChaID);
                this.load();
            }
        },
        mounted(){
            this.load();
        },
        methods: {
            load(){
                if(this.ChaID == 0) return;
                var filter = {};
                filter.ChaID = this.ChaID;
                console.log(filter);
                _sPrfChamberColumnService.list(filter, this.$fun.getUserID())
                    .then((r) => {
                        this.itemsRows = r.data;
                    })
            },
            select(item){
                console.log(item);
                this.$emit("select", item);
            },
        }
    }

</script>