<template>
    <v-card>
        <br>
            <v-row>
                <s-toolbar
                    save
                    @save="saveTmpEnvir()"
                    :color="'#BAB6B5'"
                    dark
                    label=""
                    close
                    @close="close()"
                />
            </v-row>
        <v-row style="margin: auto" align="center" justify="center">
			<v-col cols="12" lg="6" justify="center">
				<v-card class="mx-auto" outlined>
					<v-list-item three-line>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								<b>TEMPERATURA AMBIENTE (°C)</b>
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-avatar
							tile
							size="100"
							color="black"
							style="box-shadow: 1px 0px 4px 5px"
							><b style="color: red; font-size: 26px"
								><h1>
									{{ TemperatureZitrap.TmpTemperature01 }}
									<s-text
										v-model="TemperatureZitrap.TmpTemperature01"
										style="background: white"
									>
									</s-text></h1></b
						></v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-col> 
        </v-row>
		<v-row style="margin: auto" align="center" justify="center">
			<!-- <v-col cols="12" lg="3" md="3">
				<v-btn width="100%" small color="info" @click="saveTmpEnvir()"> Guardar Temp. Amb. </v-btn>
			</v-col> -->
			<v-col cols="12" lg="3" md="3">
				<v-btn width="100%" small color="success" @click="showModalTemperatures()">
					<!-- <i class="fas fa-temperature-low"></i> -->
                     Ver Historial
				</v-btn>
			</v-col>
		</v-row>
        <v-dialog
			v-model="dialogTemperatureRecords"
			width="800"
		>
			<temperature-records
				@closeModalTemperatures="closeModalTemperatures()"
				:item="item"
			></temperature-records>
		</v-dialog>
    </v-card>
</template>
<script>

    import TemperatureRecords from "./TemperatureRecords.vue";

    export default {
        components:{
            TemperatureRecords
        },
        props:{
            item:{
                default: null
            }
        },
        data(){
            return {
                dialogTemperatureRecords: false,
                TemperatureZitrap: {TmpTemperature01: 0, TmpTemperature02: 0, TmpTemperature03: 0},
            }
        },
        methods:{
            showModalTemperatures()
			{
				this.dialogTemperatureRecords = true;
			},
            saveTmpEnvir(){

                if(this.item.ChaCountCamera <= 0 ){
                    this.$fun.alert("Asigne Pallet para registrar temperaturas", "warning")
                    return;
                }

                if(this.item.CocDateInitialProcess == null && this.item.TypeChamberTunnel == 2){
                    this.$fun.alert("Error, Para registrar temperaturas de Pulpa, Inicie Proceso", "warning")
                    return;
                }

                this.CoeID = 0;

                this.item.CoeTempEnvir01 = this.TemperatureZitrap.TmpTemperature01
                this.item.CoeTempEnvir02 = this.TemperatureZitrap.TmpTemperature02
                this.item.CoeTempEnvir03 = this.TemperatureZitrap.TmpTemperature03
                this.item.CoeHour = this.$fun.getHour();
                this.item.CoeDate = this.$fun.getDate();
                this.item.SecStatus = 1;
                this.item.UsrCreateID = this.$fun.getUserID();

                console.log("Guardar", this.item);
                this.$fun.alert("¿Seguro de Guardar?", "question").then((val) => {
                    if (val.value) {

                        _sTemperaturePalletService
                            .saveEnvironment(this.item, this.$fun.getUserID())
                            .then((resp) => {
                                if (resp.status == 200) {
                                    this.$fun.alert("Registrado correctamente", "success")
                                    this.closeDetail();
                                }
                            });
                    }
                });
                },
            close(){
                this.$emit("close");
            },
            closeModalTemperatures(){

                this.dialogTemperatureRecords = false;
            }
        },
    }

</script>