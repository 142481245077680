<template>
    <div>
        <!-- :disabled="items.TypeDoor > 0 ? false : true"
        full
        autocomplete
        @input="inputNumberGuide($event)" 
        :slotTmp="true"
        v-model="RcfID" 
        -->
        <!-- <s-select 
            :items="itemsRows"
            item-value="ChrID" 
            item-text="ChrDescription"
            label="Filas" 
        > -->

        <!-- </s-select> v-model="column"-->
        <!-- <v-radio-group
            
            row
            >
            <v-radio 
                
                :label="item.ChrDescription"
                :value="item.ChrID"
            ></v-radio>
         </v-radio-group>  -->
         <p>Carril</p>
        <v-btn-toggle
          color="success" 
          group
        >
          <v-btn 
            @click="select(item)"
            v-for="item in itemsPosition" :value="item.ChpID">
            {{item.ChpPosition}}
          </v-btn> 
        </v-btn-toggle>
     
    </div>
</template>
<script>
 
    import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";

    export default {
        props: {
            ChaID : {
				required: false,
				default: 0,
            }
        },
  		components: {  },
		data () {
			return { 
                itemsPosition: [],
            }
        },
        watch:{
            ChaID(){
                console.log(this.ChaID);
                this.load();
            }
        },
        mounted(){
            this.load();
        },
        methods: {
            load(){
                if(this.ChaID == 0) return;
                var filter = {};
                filter.ChaID = this.ChaID;
                console.log(filter);
                //  this.itemsRows = r.data;

                 
                 _sPrfChamberService .listChamberPosition( filter, this.$fun.getUserID() )
                    .then((resp) => {
                        if (resp.status == 200) { 
                            this.itemsPosition = resp.data; 
                        }
                    });
								
            },
            select(item){
                console.log(item);
                this.$emit("select", item);
            },
        }
    }

</script>