<template>
    <div>
        <!-- :disabled="items.TypeDoor > 0 ? false : true"
        full
        autocomplete
        @input="inputNumberGuide($event)" 
        :slotTmp="true"
        v-model="RcfID" 
        -->
        <!-- <s-select 
            :items="itemsRows"
            item-value="ChrID" 
            item-text="ChrDescription"
            label="Filas" 
        > -->

        <!-- </s-select> v-model="column"-->
        <!-- <v-radio-group
            
            row
            >
            <v-radio 
                
                :label="item.ChrDescription"
                :value="item.ChrID"
            ></v-radio>
         </v-radio-group>  -->
         <p>Filas</p>
        <v-btn-toggle
          color="primary" 
          group
        >
          <v-btn 
            @click="select(item)"
            v-for="item in itemsRows" :value="item.ChrID">
            {{item.ChrDescription}}
          </v-btn> 
        </v-btn-toggle>
     
    </div>
</template>
<script>

import _sPrfChamberRowsService from "@/services/FreshProduction/PrfChamberRowsService";

    export default {
        props: {
            ChaID : {
				required: false,
				default: 0,
            }
        },
  		components: {  },
		data () {
			return { 
                itemsRows: [],
            }
        },
        watch:{
            ChaID(){
                console.log(this.ChaID);
                this.load();
            }
        },
        mounted(){
            this.load();
        },
        methods: {
            load(){
                if(this.ChaID == 0) return;
                var filter = {};
                filter.ChaID = this.ChaID;
                console.log(filter);
                _sPrfChamberRowsService.list(filter, this.$fun.getUserID())
                    .then((r) => {
                        this.itemsRows = r.data;
                    })
            },
            select(item){
                console.log(item);
                this.$emit("select", item);
            },
        }
    }

</script>